/**
 * 查询单个设备实时数据
 */
export const getDeviceRealTimeDataApi = (deviceCode: string) => useGet<DeviceRealTimeData>('/engine/device-ability/device-real-time', { deviceCode })

/**
 * 更新单个设备实时数据
 */
export const updateDeviceRealTimeDataApi = (deviceCode: string, devicePropertyCode: string, value: any) => usePost<any>('/engine/device-ability/writeProperty', { deviceCode, devicePropertyCode, value })

/**
 * 查询指定设备的所有能力
 */
export async function getDeviceAbilityListApi(deviceCode: string) {
  const res = await useGet<any[]>('/deploy/device-ability/list', { deviceCode })
  res.forEach((item) => {
    if (item.bindRule != null) {
      item.bindRule = JSON.parse(item.bindRule)
    }
  })

  return res
}

/**
 * 新增设备能力
 */
export const addDeviceAbilityApi = (deviceAbility: any) => usePost('/link/device-ability', deviceAbility)

/**
 * 修改设备能力
 */
export const updateDeviceAbilityApi = (deviceAbility: any) => usePut('/link/device-ability', deviceAbility)

/**
 * 删除设备能力
 */
export const deleteDeviceAbilityApi = (deviceAbilityId: number) => useDelete(`/deploy/device-ability/${deviceAbilityId}`)

/**
 * 分页查询控制日志
 */
export const queryDeviceControlLogApi = (pageParams: any) => useGet('/engine/device-ability/page-log-device-control', pageParams)
